import { Suspense, lazy } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <AboutPage /> },
    { path: '/blogs', element: <Blogs /> },
  ]);
}

// IMPORT COMPONENTS

const AboutPage = Loadable(lazy(() => import('../pages/About')));
const Blogs = Loadable(lazy(() => import('../pages/Blogs')));