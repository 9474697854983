import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { PATH_PAGE } from '../routes/paths'
import { GithubLogo } from "@phosphor-icons/react";
import { goToSocialLink } from "../utils";

export default function Header() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">

      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <Link to={PATH_PAGE.home}>About</Link>
      </Typography>

      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <Link to={PATH_PAGE.blogs}>Blogs</Link>
      </Typography>

    </ul>
  );

  return (
    <Navbar className="sticky top z-10 h-max max-w-full rounded-b-lg top-0 py-2 px-4 lg:px-8 lg:py-4">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="/"
          className="mr-4 cursor-pointer py-1.5 font-medium"
        >
          Abhay Braja
        </Typography>
        <div className="flex items-center gap-4">
          <div className="mr-4 hidden lg:block">{navList}</div>
          {/* <Button
            variant="outlined"
            size="sm"
            className="hidden lg:inline-block text-gray-900 border-gray-900"
          >
            <div className="flex items-center gap-2">
              <span>CV </span>
              <DownloadSimple size={20} weight="fill" />
            </div>

          </Button> */}
          <IconButton variant="text" className="rounded-full" color="blue-gray" onClick={() => goToSocialLink("tw")}>
            {/* <TwitterLogo size={20} weight="fill" /> */}
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1054 10.3271L19.4266 3H17.9287L12.44 9.36201L8.05619 3H3L9.62916 12.6205L3 20.3041H4.498L10.2942 13.5856L14.9238 20.3041H19.98L13.105 10.3271H13.1054ZM11.0537 12.7052L10.382 11.7473L5.03776 4.12449H7.3386L11.6515 10.2763L12.3232 11.2343L17.9294 19.2307H15.6285L11.0537 12.7056V12.7052Z" fill="#4B5669" />
            </svg>

          </IconButton>
          <IconButton variant="text" className="rounded-full" color="blue-gray" onClick={() => goToSocialLink("github")}>
            <GithubLogo size={20} weight="fill" />
          </IconButton>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
      </div>
      <MobileNav open={openNav}>
        {navList}
        <Button variant="gradient" size="sm" fullWidth className="mb-2" color="blue">
          <span>Download Resume</span>
        </Button>
      </MobileNav>
    </Navbar>
  );
}