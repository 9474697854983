import Header from './Header'
import Footer from './Footer'

export default function Layout({ children }) {
  return (
    <div className='mx-auto max-w-screen-lg'>
      <Header />
      {children}
      <Footer />
    </div>
  );
}