import { Spinner } from '@material-tailwind/react';
import PropTypes from 'prop-types';


// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard }) {
  return (
    <>
      <Spinner />
    </>
  );
}
