export function goToSocialLink(media, email) {
    var link = ""
    if (media === "tw") {
        link = "https://twitter.com/abhay_braja";
    } else if (media === "github") {
        link = "https://github.com/abhaybraja";
    } else if (media === "mail") {
        window.location.href = `mailto:${email}`;
    }else if (media === "li") {
        link = "https://www.linkedin.com/in/abhaybraja/";
    }else if (media === "stackoverflow") {
        link = "https://stackoverflow.com/users/16785377/abhay-braja?tab=profile";
    } else {
        return
    }
    window.open(link, "__blank");
}